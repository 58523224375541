import { isAuthor, namespace } from './constants';
/**
 * Get all `slot` elements that AEM outputs earlier and saves the slot name and it's content in an array of objects.
 * The content will be later used to be injected into the Vue 3 component as slots.
 * @param target - The target element. Must be the wrapper of the Vue 3 Component
 * @returns `SlotArray[]`
 */
export const getSlots = (target) => {
    const slotElements = target.querySelectorAll('div[slot]');
    const slots = [];
    slotElements.forEach((slotElement) => {
        const slotName = slotElement.getAttribute('slot');
        const isWebComponent = slotElement.firstElementChild?.getAttribute('data-integration-type') === 'webcomponent';
        const componentName = slotElement.querySelector('div[data-component-name]')?.getAttribute('data-component-name') || '';
        if (slotName) {
            slots.push({
                name: slotName,
                componentName: componentName,
                content: slotElement,
                isWebComponent,
            });
            slotElement.remove();
        }
    });
    return slots;
};
/**
 * Picks the `slots` object previously get by the `getSlots` method (recommended) and
 * maps the `template` tags with the slot name and it's content for each slot.
 *
 * If view of the component is author mode (EDITING), component will mount directly to container so it returns the innerHTML for viewing purposes.
 *
 * If not, component will return the `component` tag based on `componentName`.
 * @param slots - Array of objects with the slot name and the slot content HTML.
 * @returns `string[]`
 */
export const injectSlots = (slots) => {
    return slots.map((slot) => {
        const renderedElement = isAuthor || slot.isWebComponent
            ? slot.content.innerHTML
            : `<div data-component-name="${slot.componentName}"></div>`;
        return `<template v-slot:${slot.name}>${renderedElement}</template>`;
    });
};
/**
 * Dispatches an event that will mount a component in a specific element.
 * @param componentName
 * @param element
 */
export const mountLazyComponent = (componentName, element) => {
    document.dispatchEvent(new CustomEvent(`mount-component-${componentName}`, {
        detail: {
            componentName: componentName,
            element: element,
        },
    }));
};
/**
 * Adds a event listener that will be aware of when a component is ready to be mounted.
 * @param componentName
 * @param mountFunction
 */
export const addLazyMountEvent = (componentName, mountFunction) => {
    document.addEventListener(`mount-component-${componentName}`, (event) => {
        if (!event.detail || !event.detail.componentName || !event.detail.element) {
            console.warn(`${namespace}: wrong event detail provided`);
        }
        else {
            const componentName = event.detail.componentName;
            mountFunction({ element: event.detail.element, componentName });
        }
    });
};
/**
 * Mounts a component in AEM Environment.
 * If the mounting element is not found, it will invoke the addLazyMountEvent method.
 * @param componentName
 * @param mountFunction
 */
export const aemMountComponent = (componentName, mountFunction) => {
    const componentElements = document.querySelectorAll(`div[data-component-name="${componentName}"]`);
    if (componentElements.length) {
        componentElements.forEach((element) => {
            mountFunction({ element, componentName });
        });
    }
    else {
        addLazyMountEvent(componentName, mountFunction);
    }
};
